<template>
  <div class="home">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import { onBeforeMount } from 'vue'
import { quote } from '../libs/qq'
export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  setup() {
    onBeforeMount(async () => {
      let qq = await quote()
      console.log(1)
      console.log(qq)
    })
  }
}
</script>
