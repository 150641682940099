import { AlphaRouter } from '@uniswap/smart-order-router'
import { TradeType, CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { ethers } from 'ethers'
import JSBI  from 'jsbi'
// const V3_SWAP_ROUTER_ADDRESS = '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45'
const WALLET_ADDRESS = '0xFf8505C6fFddFE170121cD5A3C052A594bB74317'

function getProvider() {
  return new ethers.providers.JsonRpcProvider('https://polygon-mumbai.g.alchemy.com/v2/Ibc8Wmx-67KP8shNQs4sd9YE8Yfma2Mk')
}
const chainId = 80001
const router = new AlphaRouter({ chainId, provider: getProvider()});
// const amountIn = web3.utils.toWei('1', 'ether');
const name0 = 'Wrapped Matic'
const symbol0 = 'WMatic'
const decimals0 = 18
const address0 = '0x9c3c9283d3e44854697cd22d3faa240cfb032889'

const name1 = 'MUMBAI PEPE'
const symbol1 = 'PEPE'
const decimals1 = 18
const address1 = '0xec0aef09694062349bcb50184f8a0af469e7f294'
const WMatic = new Token(chainId, address0, decimals0, symbol0, name0)
const PEPE = new Token(chainId, address1, decimals1, symbol1, name1)
const wei = ethers.utils.parseUnits('20', 18)
const inputAmount = CurrencyAmount.fromRawAmount(PEPE, JSBI.BigInt(wei))

export async function quote() {
  const route = await router.route(
    inputAmount,
    WMatic,
    TradeType.EXACT_INPUT,
    {
      recipient: WALLET_ADDRESS,
      slippageTolerance: new Percent(25, 100),
      deadline: Math.floor(Date.now()/1000 + 1800)
    }
  )
  console.log(`Quote Exact In: ${route}`)
  return route
}